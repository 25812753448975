import React from 'react';
import { StyledPrivacy } from './Button.styled';
import {Link} from "react-router-dom";

const Button = ({text, page}) => {

    return (
        <StyledPrivacy>
        <a href={page} style={{color:"white"}}>
            {text}
        </a>
        </StyledPrivacy>
    )
};

export default Button;
