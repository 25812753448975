import styled from 'styled-components';

export const StyledPrivacy = styled.div`
  background: ${({theme}) => theme.secondary};
  border: 1px solid ${({theme}) => theme.line1};
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  font-family: "Modern", sans-serif;
  width: 100px;
  height: auto;

  a {
    color: ${({theme}) => theme.background};
    text-decoration: none;
    
  }

  }
`;
