import styled from 'styled-components';

export const StyledLogo = styled.img`
  position: relative;
  top: 10%;
  padding: 20;
  align-self: center;
  left: 1rem;
  object-fit: cover;
  width: 175px;
  height: 30px;



`
