import React, { useState, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { useOnClickOutside } from '../hooks';
import { GlobalStyles } from '../styles/global';
import { theme } from '../styles/theme';
import { Burger, Menu, Logo, Barra } from './index';
import "../scenes/normalize.css";
import Button from './Button/Button';

function Header() {
    const [open, setOpen] = useState(false);
    const node = useRef();
    const menuId = "main-menu";

    useOnClickOutside(node, () => setOpen(false));

    return (
        <ThemeProvider theme={theme}>
                <GlobalStyles />
                <div className="header">
                    <Logo/>
                    <Button page={"mailto:pebonazzi@gmail.com"} text={"Join Us"}></Button>
                </div>

        </ThemeProvider>
    );
}

export default Header;
