import React from "react";
import "../scenes/normalize.css";
import Ufficio from "./Ufficio/Ufficio";
import Button from "./Button/Button";
import {ThemeProvider} from "styled-components";
import {theme} from "../styles/theme";
import {GlobalStyles} from "../styles/global";
import Logo from "./Logo";
import {ColoredLine} from "./ColoredLine";
import Ufficio2 from "./Ufficio/Ufficio2";
import sph from './sph.png';
import logo from './picovolts.png';

function Footer() {

    const citta = [
        'Office',
    ];

    const street_comma_num = [
        'ETH Student Project House',
    ];

    const zip_comma_town = [
        'Clausiusstrasse 16, 8006 Zürich (CH)',
    ];


    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div className="highbox">
            <div className='footer'>
                <Ufficio citta={citta[0]} street_comma_num={street_comma_num[0]} zip_comma_town={zip_comma_town[0]} map={""} />
            </div>
            <ColoredLine color={theme.line1}/>
            <div className='end'>
                <img src={logo}/>
                <img src={sph}/>
            </div>
        </div>

        </ThemeProvider>
    );
}

export default Footer;
