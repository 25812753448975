import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../styles/global';
import { theme } from '../styles/theme';
import "../scenes/normalize.css";
import sample from './sample.mp4';

function HomeC() {

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <div className='home_container'>
            <video className='videoTag' autoPlay loop playsinline muted style={{width: "100%", marginBottom:"20px", objectFit:"cover"}}>
                <source src={sample} type='video/mp4' />
            </video>
            </div>
            
        </ThemeProvider>

    );
}

export default HomeC;
