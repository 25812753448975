import React from 'react';
import { StyledLogo } from './Logo.styled';
import {Link} from "react-router-dom";
import logo from './picovolts.png';

const Logo = () => {
    return (
        <Link to="/">
        <StyledLogo
            alt={''}
            src={logo}>
        </StyledLogo>
        </Link>
    )
}
export default Logo;
