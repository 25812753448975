import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Redirect, BrowserRouter as Router, Switch} from "react-router-dom";
import Home from "./scenes/home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {theme} from "./styles/theme";
import {ColoredLine} from "./components/ColoredLine";

export const routing = (
    <Router>
        <div>
            <Header />
            <ColoredLine color={theme.line1}/>
            <Switch>
                <Route exact path="/" component={Home} />
                <Redirect to="/404" />
            </Switch>
            <Footer />
        </div>
    </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
